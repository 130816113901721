import { selectPromotion } from 'store/campaign/campaignActions';
import { EventType, createEvent } from '@qantasexperiences/analytics';

const emitSelectPromotion = ({ payload }) => {
  if (payload.isGA4) {
    const selectPromotionEvent = createEvent({
      type: EventType.SELECT_PROMOTION,
      payload: {
        promotion: payload.promotion,
      },
    });

    return selectPromotionEvent;
  }
};

// eslint-disable-next-line
// @ts-ignore
export default { [selectPromotion]: emitSelectPromotion };
