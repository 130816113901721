import { useEffect, useCallback, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import map from 'lodash/map';
import {
  login,
  loginFailure,
  logout,
  setAuthentication,
  trackAuthentication,
  clearAuthentication,
  setAuthenticationCompleted,
} from 'store/user/userActions';
import { updateUser as updatePersonalisationUser } from 'store/personalisation/personalisationActions';
import { getAuthenticationDetails } from 'lib/qffAuth';
import { AUTH_REFRESH_INTERVAL, OAUTH_ENABLED } from 'config';
import { getTreatments } from 'store/split/splitSelectors';
import { useIsAuthenticated } from 'lib/oauth';
import { useInterval } from 'react-use';
import { useQffAuth } from './useQffAuth';

const withAuthenticationDetails = async (qffAuth, fn) => {
  try {
    const authenticationDetails = await getAuthenticationDetails(qffAuth);
    fn(authenticationDetails);
  } catch (error) {
    // sometimes the qffAuth client can report as authenticated for an expired token
    // (maybe when coming back to a tab that's been inactive for some time?).
    // When this fails during fetching the details for the user from the server,
    // this ensures that the user is logged out in the front end rather than being
    // maintained as logged in with an expired token.
    // Do not invoke on 404 - if the user has temporary connection issues we don't want to log them out
    if ([401, 422].includes(error?.response?.status)) {
      qffAuth.logout();
    }
  }
};

const QffAuthEventSubscriber = () => {
  const [polling, setPolling] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const qffAuth = useQffAuth();

  const splitTreatments = useSelector(getTreatments);
  const oauthTreatmentEnabled = splitTreatments?.login_service?.treatment === 'oauth';
  const isOauthLoginFlow = OAUTH_ENABLED && oauthTreatmentEnabled;

  // keep the token refreshed and the user logged in
  useInterval(
    () => {
      if (qffAuth && qffAuth.isAuthenticated()) {
        qffAuth.init();
      }
    },
    polling ? AUTH_REFRESH_INTERVAL : null,
  );

  const handleAuthenticationInit = useCallback(async () => {
    if (qffAuth.isAuthenticated()) {
      await withAuthenticationDetails(qffAuth, (authenticationDetails) => {
        dispatch(setAuthentication(authenticationDetails));
        dispatch(updatePersonalisationUser());
      });
    } else if (!qffAuth.isAuthenticated() && isAuthenticated) {
      // This logout does not pass 'emitAnalyticsEvent: true' like logoutSuccess because after an
      // intentional logout the isAuthenticated value is not yet updated to false when
      // handleAuthenticationInit is called. This caused a double GA event of logout for single event.
      dispatch(logout());
    }
    dispatch(setAuthenticationCompleted());
  }, [dispatch, isAuthenticated, qffAuth]);

  const eventCallbacks = useMemo(
    () => ({
      loginSuccess: async () => {
        await withAuthenticationDetails(qffAuth, (authenticationDetails) => {
          dispatch(login(authenticationDetails));
          // you might be tempted to dispatch `updatePersonalisationUser()` here as well but it gets
          // triggered in the `useEffect below indirectly through the isAuthenticated state causing `handleAuthenticationInit`
          // to return a new function. This is pretty confusing and should probably be looked into to find
          // a clearer way of achieving this
        });
      },

      loginFailure: () => {
        dispatch(clearAuthentication());
        dispatch(loginFailure());
      },

      logoutSuccess: () => {
        // 'emitAnalyticsEvent: true' added because, after an intentional logout,
        // when handleAuthenticationInit is called the isAuthenticated value is not yet updated to false.
        // This caused a double logout GA event being fired for a single event.
        dispatch(logout({ emitAnalyticsEvent: true }));
      },

      // There is an outstanding issue lodged with the SSO team that when logging out in one tab,
      // the other tab remains logged in, even after a re-initialization triggering this event.
      initCompleted: () => {
        handleAuthenticationInit();
      },

      // Primarily intended to ensure we get the new access token when it's refreshed
      syncProfileUpdate: async () => {
        await withAuthenticationDetails(qffAuth, (authenticationDetails) => {
          dispatch(setAuthentication(authenticationDetails));
        });
      },
    }),
    [dispatch, handleAuthenticationInit, qffAuth],
  );

  useEffect(() => {
    if (!qffAuth || isOauthLoginFlow) return;

    setPolling(true);
    map(eventCallbacks, (cb, event) => qffAuth.addEventListener(event, cb));
    handleAuthenticationInit(); // in case the server wasn't able to verify the correct auth state.

    if (qffAuth.isAuthenticated()) {
      withAuthenticationDetails(qffAuth, (authenticationDetails) => {
        dispatch(trackAuthentication(authenticationDetails));
      });
    }

    return () => {
      setPolling(false);
      map(eventCallbacks, (cb, event) => qffAuth.removeEventListener(event, cb));
    };
  }, [handleAuthenticationInit, eventCallbacks, qffAuth, dispatch, isOauthLoginFlow]);

  return null;
};

export default QffAuthEventSubscriber;
