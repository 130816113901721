import { useEffect, useState } from 'react';
import { getQffAuth } from 'lib/qffAuth';

export const useQffAuth = () => {
  const [qffAuth, setQffAuth] = useState(null);

  useEffect(() => {
    const subscribeToLoginEvents = async () => {
      try {
        const qffAuth = await getQffAuth();
        setQffAuth(qffAuth);
      } catch (e) {
        //intentionally swallow as we can't subscribe to these events without qffAuth
      }
    };

    subscribeToLoginEvents();
  }, []);

  return qffAuth;
};
