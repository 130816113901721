import { getTreatments } from './splitSelectors';
import { registerSplits } from './splitActions';

const RETRY_INTERVAL = 5;
const TIMEOUT = 2000;

export const resolveSplitTreatment = async ({ dispatch, getState, splitName }) => {
  let treatments = getTreatments(getState());
  let treatment = treatments[splitName];
  if (treatment) return Promise.resolve(treatment);

  dispatch(registerSplits([splitName]));

  return new Promise((resolve) => {
    let retries = 0;

    const attemptResolve = () => {
      setTimeout(() => {
        treatments = getTreatments(getState());
        treatment = treatments[splitName];

        if (treatment) {
          resolve(treatment);
        } else if (retries * RETRY_INTERVAL > TIMEOUT) {
          resolve({ treatment: 'control' });
        } else {
          retries++;
          attemptResolve();
        }
      }, RETRY_INTERVAL);
    };

    attemptResolve();
  });
};
