import { viewPromotion } from 'store/campaign/campaignActions';
import { EventType, createEvent } from '@qantasexperiences/analytics';

const emitViewPromotion = ({ payload }) => {
  if (payload.isGA4) {
    const viewPromotionEvent = createEvent({
      type: EventType.VIEW_PROMOTION,
      payload: {
        promotion: payload.promotion,
      },
    });

    return viewPromotionEvent;
  }
};

// eslint-disable-next-line
// @ts-ignore
export default { [viewPromotion]: emitViewPromotion };
