import { beginCheckout } from 'store/checkout/checkoutActions';
import { EventType, createEvent } from '@qantasexperiences/analytics';

const emitBeginCheckout = ({ payload }) => {
  if (payload.isGA4) {
    const beginCheckoutEvent = createEvent({
      type: EventType.BEGIN_CHECKOUT,
      payload: {
        quote: payload.quote,
        payments: payload.payments,
        pointsConversion: payload.pointsConversion,
        isRebooked: payload.isRebooked,
      },
    });

    return beginCheckoutEvent;
  }
};

// eslint-disable-next-line
// @ts-ignore
export default { [beginCheckout]: emitBeginCheckout };
