import { getQffAuth } from 'lib/qffAuth';

const loginQffUser = async () => {
  try {
    const qffAuth = await getQffAuth();
    qffAuth.showLoginModal();
  } catch (e) {
    // The QFF widget will sometimes fail to load due to flakey network issues. Loyalty has already deprecated the widget in favour of a QFF API.
    // Do not log widget failures into sentry, as this does not help us with fixing anything
  }
};

export default loginQffUser;
