import loadQffAuthScript from './loadQffAuthScript';
import memoize from 'lodash/memoize';

const getQffAuth = memoize(async () => {
  if (!window.qff_auth) {
    await loadQffAuthScript();
  }

  return initialized();
});

const initialized = () => {
  if (window.qff_auth.isInitialised()) {
    return Promise.resolve(window.qff_auth);
  } else {
    return new Promise((resolve) => {
      window.qff_auth.subscribeInitCompleted(() => resolve(window.qff_auth));
    });
  }
};

export default getQffAuth;
