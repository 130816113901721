import { addToCart } from 'store/checkout/checkoutActions';
import { EventType, createEvent } from '@qantasexperiences/analytics';

const emitAddToCart = ({ payload }) => {
  if (payload.isGA4) {
    const addToCartEvent = createEvent({
      type: EventType.ADD_TO_CART,
      payload: {
        property: payload.property,
        offer: payload.offer,
        roomType: payload.roomType,
        query: payload.query,
        initialCash: payload.initialCash,
        pointsConversion: payload.pointsConversion,
        isRebooked: payload.isRebooked,
      },
    });

    return addToCartEvent;
  }
};

// eslint-disable-next-line
// @ts-ignore
export default { [addToCart]: emitAddToCart };
