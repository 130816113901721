import { trackBooking } from 'store/booking/bookingActions';
import pickBy from 'lodash/pickBy';
import { differenceInDays } from 'date-fns';
import { EventType, createEvent } from '@qantasexperiences/analytics';

const emitBookingConfirmationEvent = ({ payload }) => {
  if (!payload?.booking?.reservation) return null;

  if (payload.isGA4) {
    const purchaseEvent = createEvent({
      type: EventType.PURCHASE,
      payload: {
        booking: payload.booking,
        quote: payload.quote,
        isRebooked: payload.isRebooked,
      },
    });

    return purchaseEvent;
  }

  const { id, reservation } = payload.booking;
  const { offer, property, propertyBookingReference } = reservation;
  const { charges, pointsEarned } = offer;
  const bookingTotal = charges?.total?.amount || '';
  /* eslint-disable-next-line */
  const { suburb, state, country } = reservation?.property?.address;
  const affiliation = country === 'Australia' ? 'Domestic' : 'International';
  const checkIn = reservation?.checkIn || '';
  const checkOut = reservation?.checkOut || '';
  const stayDuration = differenceInDays(new Date(checkOut), new Date(checkIn));

  const voucher = payload?.booking?.bookingTotal?.voucher?.total || '';
  const creditCard = payload?.booking?.bookingTotal?.creditCard.total || '';
  const creditNote = payload?.booking?.bookingTotal?.creditNote.total || '';
  const qantasGroupCreditVoucher = payload?.booking?.bookingTotal?.qantasGroupCreditVoucher.total || '';
  const points = payload?.booking?.bookingTotal?.points.totalPoints || '';

  const paymentType = () => {
    const paymentTypes = {
      voucher: voucher,
      creditCard: creditCard,
      creditNote: creditNote,
      qantasGroupCreditVoucher: qantasGroupCreditVoucher,
      points: points,
    };
    return pickBy(paymentTypes, (item, key) => (parseInt(item) > 0 ? key : null));
  };

  const pointsInCash =
    parseInt(bookingTotal) - parseInt(voucher) - parseInt(creditNote) - parseInt(qantasGroupCreditVoucher) - parseInt(creditCard);
  const pointsPayPercentage = parseInt(points) > 0 ? ((pointsInCash / parseInt(bookingTotal)) * 100).toFixed() : 0;

  return {
    event: 'purchase',
    ecommerce: {
      transaction_id: id,
      possible_payment_types: 'Voucher, Travel Pass, Credit Card, Qantas Credit and Points',
      payment_type: paymentType(),
      affiliation: affiliation,
      value: bookingTotal,
      tax: charges?.payableAtBooking?.tax?.amount,
      currency: charges?.total?.currency,
      coupon: propertyBookingReference,
      totalPointsUsed: parseInt(points) > 0 ? points : 0,
      totalCashUsed: parseInt(creditCard) > 0 ? creditCard : 0,
      purchasePointsEarn: pointsEarned?.qffPoints?.total,
      payInPointsPercentage: pointsPayPercentage,
      items: [
        {
          item_id: property?.id,
          item_name: property?.name,
          price: bookingTotal,
          currency: charges?.total?.currency,
          quantity: stayDuration,
          item_category: country,
          item_category2: state,
          item_category3: suburb,
          index: 0,
          prodPointsUsed: parseInt(points) > 0 ? points : 0,
          prodCashUsed: parseInt(creditCard) > 0 ? creditCard : 0,
          pointsCashPrice: bookingTotal,
          productPointsEarn: pointsEarned?.qffPoints?.total,
        },
      ],
    },
  };
};

// eslint-disable-next-line
// @ts-ignore
export default { [trackBooking]: emitBookingConfirmationEvent };
