import { getTreatments } from 'store/split/splitSelectors';
import { setCampaign, clearCampaign } from 'store/campaign/campaignActions';
import { fetchCampaign } from 'lib/clients/fetchCampaign';
import isEmpty from 'lodash/isEmpty';
import { VPP_CAMPAIGN } from 'config';
import { getPathName } from 'store/router/routerSelectors';

export default async ({ store }) => {
  const state = store.getState();
  const pathName = getPathName(state);

  if (!pathName) return;

  try {
    const campaign = await fetchCampaign();

    if (!isEmpty(campaign)) {
      store.dispatch(setCampaign(campaign));
    } else {
      const { blue_banner_message: localCampaign, vpp } = getTreatments(state);
      const localCampaignConfig = isEmpty(localCampaign) ? null : localCampaign.config;

      if (vpp?.treatment === 'on') {
        store.dispatch(setCampaign(VPP_CAMPAIGN));
      } else if (localCampaign?.treatment === 'local_campaign_live' && !isEmpty(localCampaignConfig)) {
        store.dispatch(setCampaign(localCampaignConfig));
      } else {
        store.dispatch(clearCampaign());
      }
    }
  } catch (e) {
    store.dispatch(clearCampaign());
  }
};
