import { useSelector } from 'react-redux';
import { getIsAuthenticated, getIsAuthenticatedOAuth } from 'store/user/userSelectors';
import { getTreatments } from 'store/split/splitSelectors';

export const useIsAuthenticated = () => {
  const isAuthenticatedOld = useSelector(getIsAuthenticated);
  const isAuthenticated0Auth = useSelector(getIsAuthenticatedOAuth);

  const splitTreatments = useSelector(getTreatments);
  return splitTreatments?.login_service?.treatment === 'oauth' ? isAuthenticated0Auth : isAuthenticatedOld;
};
