import fetchMemberDetails from 'lib/clients/fetchMemberDetails';

const getAuthenticationDetails = async (qffAuth) => {
  const memberId = qffAuth.getMemberId();
  const accessToken = qffAuth.getAccessToken();
  const memberDetails = await fetchMemberDetails({ memberId, accessToken });

  return {
    memberId,
    accessToken,
    ...memberDetails,
  };
};

export default getAuthenticationDetails;
