import { getAccessToken, getQhUserId } from 'store/user/userSelectors';
import * as selectors from '../selectors';
import * as personalise from 'lib/clients/personalise';
import { resolveSplitFlag } from 'store/split/resolveSplitFlag';
import { PERSONALISE_TRACKING_ENABLED } from 'config';

export function createInteractionEventHandlers({ getState, dispatch }) {
  const trackInteraction = async (properties) => {
    if (!PERSONALISE_TRACKING_ENABLED) return;

    const shouldTrack = await resolveSplitFlag({
      getState,
      dispatch,
      splitName: 'personalisation_interaction_events',
    });

    if (!shouldTrack) return;

    const payload = {
      ...selectors.getCommonEventProperties(getState()),
      ...properties,
      sentAt: new Date().toISOString(),
    };

    await personalise.trackInteractionEvent(payload, {
      accessToken: getAccessToken(getState()),
      qhUserId: getQhUserId(getState()),
    });
  };

  return {
    trackView(id) {
      return trackInteraction({
        itemId: id,
        eventType: 'view',
      });
    },
    trackBooking(id) {
      return trackInteraction({
        itemId: id,
        eventType: 'booking',
      });
    },
    trackPromoClick(id) {
      return trackInteraction({
        itemId: id,
        eventType: 'click',
        impression: selectors.getSearchPromoImpression(getState()),
      });
    },
    trackSearchClick(id) {
      return trackInteraction({
        itemId: id,
        eventType: 'click',
        impression: selectors.getSearchListImpression(getState()),
      });
    },
    trackMapClick(id) {
      return trackInteraction({
        itemId: id,
        eventType: 'click',
      });
    },
    trackRecommendationClick(id) {
      return trackInteraction({
        itemId: id,
        eventType: 'click',
        impression: selectors.getPropertyPageRecommendationsImpression(getState()),
      });
    },
  };
}
